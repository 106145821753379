<template>
  <div>
    <div
      v-if="!isEmpty(userData.searchType)"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table
          dense
          class="tableCss1"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  rowspan="4"
                >
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="8"
                >
                  GF
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                  colspan="18"
                >
                  1F
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block A
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block B
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block C
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block D
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block E
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block F
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  -
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block G
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block H
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block I
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor GF-1
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor GF-2
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor GF-3
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor GF-4
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-1
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-2
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-3
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  Cor 1F-4
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-5
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-6
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-7
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-8
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 1F-9
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  U↑
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  D↓
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="`${index}${item.time}`"
                :class="getClass(item.time, index)"
              >
                <td
                  v-if="userData.searchType === 1 && index % 2 === 0"
                  class="text-center"
                  :rowspan="index % 2 === 0 ?'2':'' "
                >
                  {{ item.time }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  class="text-center"
                >
                  {{ item.time }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count1 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count2 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count3 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count4 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count5 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count6 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count7 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count8 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count9 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count10 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count11 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count12 }}
                </td>
                <td
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                  class="text-right"
                >
                  {{ item.count13 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count14 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count15 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count16 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count17 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count18 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count19 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count20 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count21 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count22 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count23 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count24 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count25 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count26 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table1FooterData"
                :key="`${index}${item.time}`"
              >
                <template v-if="index ===0">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right">
                    {{ item.count1 }}
                  </td>
                  <td class="text-right">
                    {{ item.count2 }}
                  </td>
                  <td class="text-right">
                    {{ item.count3 }}
                  </td>
                  <td class="text-right">
                    {{ item.count4 }}
                  </td>
                  <td class="text-right">
                    {{ item.count5 }}
                  </td>
                  <td class="text-right">
                    {{ item.count6 }}
                  </td>
                  <td class="text-right">
                    {{ item.count7 }}
                  </td>
                  <td class="text-right">
                    {{ item.count8 }}
                  </td>
                  <td class="text-right">
                    {{ item.count9 }}
                  </td>
                  <td class="text-right">
                    {{ item.count10 }}
                  </td>
                  <td class="text-right">
                    {{ item.count11 }}
                  </td>
                  <td class="text-right">
                    {{ item.count12 }}
                  </td>
                  <td class="text-right">
                    {{ item.count13 }}
                  </td>
                  <td class="text-right">
                    {{ item.count14 }}
                  </td>
                  <td class="text-right">
                    {{ item.count15 }}
                  </td>
                  <td class="text-right">
                    {{ item.count16 }}
                  </td>
                  <td class="text-right">
                    {{ item.count17 }}
                  </td>
                  <td class="text-right">
                    {{ item.count18 }}
                  </td>
                  <td class="text-right">
                    {{ item.count19 }}
                  </td>
                  <td class="text-right">
                    {{ item.count20 }}
                  </td>
                  <td class="text-right">
                    {{ item.count21 }}
                  </td>
                  <td class="text-right">
                    {{ item.count22 }}
                  </td>
                  <td class="text-right">
                    {{ item.count23 }}
                  </td>
                  <td class="text-right">
                    {{ item.count24 }}
                  </td>
                  <td class="text-right">
                    {{ item.count25 }}
                  </td>
                  <td class="text-right">
                    {{ item.count26 }}
                  </td>
                </template>
                <template v-if="index ===1">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count2 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count5 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count6 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count11 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count12 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count13 }}
                  </td>
                </template>
                <template v-if="index ===2">
                  <td
                    class="text-center"
                    rowspan="2"
                  >
                    {{ item.time }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count11 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count12 }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count16 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count17 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count18 }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                </template>
                <template v-if="index ===3">
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count11 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count12 }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count16 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count17 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count18 }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                </template>
                <template v-if="index ===4 || index ===5">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count2 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count5 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count6 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                </template>
                <template v-if="index ===6 || index ===7">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="8"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="18"
                  >
                    {{ item.count2 }}
                  </td>
                </template>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table
          dense
          class="tableCss1"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  rowspan="4"
                >
                </th>
                <th
                  class="text-center"
                  style="background: #ccffcc !important"
                  colspan="18"
                >
                  2F
                </th>
                <th
                  class="text-center"
                  style="background: #64fcfc !important"
                  colspan="8"
                >
                  3F
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Block J
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block K
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  -
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block L
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block M
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  -
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block N
                </th>
                <th
                  class="text-center"
                  colspan="4"
                >
                  Block O
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-1
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-2
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-3
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  Cor 2F-4
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-5
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-6
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-7
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 2F-8
                </th>
                <th
                  class="text-center"
                  colspan="2"
                  style="background:#C0C0C0 !important;"
                >
                  Cor 2F-9
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 3F-1
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 3F-2
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 3F-3
                </th>
                <th
                  class="text-center"
                  colspan="2"
                >
                  Cor 3F-4
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  U↑
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  D↓
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  L←
                </th>
                <th class="text-center"
                style="background:#C0C0C0 !important;"
                >
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
                <th class="text-center">
                  L←
                </th>
                <th class="text-center">
                  →R
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="`${index}${item.time}`"
                :class="getClass(item.time, index)"
              >
                <td
                  v-if="userData.searchType === 1 && index % 2 === 0"
                  class="text-center"
                  :rowspan="index % 2 === 0 ?'2':'' "
                >
                  {{ item.time }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  class="text-center"
                >
                  {{ item.time }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count1 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count2 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count3 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count4 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count5 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count6 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count7 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count8 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count9 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count10 }}
                </td>
                <td
                  class="text-right"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ item.count11 }}
                </td>
                <td
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                  class="text-right"
                >
                  {{ item.count12 }}
                </td>
                <td
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                  class="text-right"
                >
                  {{ item.count13 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count14 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count15 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count16 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count17 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count18 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count19 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count20 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count21 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count22 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count23 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count24 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count25 }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  class="text-right"
                >
                  {{ item.count26 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table2FooterData"
                :key="`${index}${item.time}`"
              >
                <template v-if="index ===0">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right">
                    {{ item.count1 }}
                  </td>
                  <td class="text-right">
                    {{ item.count2 }}
                  </td>
                  <td class="text-right">
                    {{ item.count3 }}
                  </td>
                  <td class="text-right">
                    {{ item.count4 }}
                  </td>
                  <td class="text-right">
                    {{ item.count5 }}
                  </td>
                  <td class="text-right">
                    {{ item.count6 }}
                  </td>
                  <td class="text-right">
                    {{ item.count7 }}
                  </td>
                  <td class="text-right">
                    {{ item.count8 }}
                  </td>
                  <td class="text-right">
                    {{ item.count9 }}
                  </td>
                  <td class="text-right">
                    {{ item.count10 }}
                  </td>
                  <td class="text-right">
                    {{ item.count11 }}
                  </td>
                  <td class="text-right">
                    {{ item.count12 }}
                  </td>
                  <td class="text-right">
                    {{ item.count13 }}
                  </td>
                  <td class="text-right">
                    {{ item.count14 }}
                  </td>
                  <td class="text-right">
                    {{ item.count15 }}
                  </td>
                  <td class="text-right">
                    {{ item.count16 }}
                  </td>
                  <td class="text-right">
                    {{ item.count17 }}
                  </td>
                  <td class="text-right">
                    {{ item.count18 }}
                  </td>
                  <td class="text-right">
                    {{ item.count19 }}
                  </td>
                  <td class="text-right">
                    {{ item.count20 }}
                  </td>
                  <td class="text-right">
                    {{ item.count21 }}
                  </td>
                  <td class="text-right">
                    {{ item.count22 }}
                  </td>
                  <td class="text-right">
                    {{ item.count23 }}
                  </td>
                  <td class="text-right">
                    {{ item.count24 }}
                  </td>
                  <td class="text-right">
                    {{ item.count25 }}
                  </td>
                  <td class="text-right">
                    {{ item.count26 }}
                  </td>
                </template>
                <template v-if="index ===1">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count2 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count5 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count6 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count11 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count12 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count13 }}
                  </td>
                </template>
                <template v-if="index ===2">
                  <td
                    class="text-center"
                    rowspan="2"
                  >
                    {{ item.time }}
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count13 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count14 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    class="text-center"
                    colspan="2"
                  >
                    {{ item.count16 }}
                  </td>
                </template>
                <template v-if="index ===3">
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-center"
                  >
                    -
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count13 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count14 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count16 }}
                  </td>
                </template>
                <template v-if="index ===4 || index ===5">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count2 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count5 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="2"
                  >
                    {{ item.count6 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="4"
                  >
                    {{ item.count8 }}
                  </td>
                </template>
                <template v-if="index ===6 || index ===7">
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td
                    class="text-right"
                    colspan="18"
                  >
                    {{ item.count1 }}
                  </td>
                  <td
                    class="text-right"
                    colspan="8"
                  >
                    {{ item.count2 }}
                  </td>
                </template>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <!-- excel出力用 -->
    <div
      v-if="!isEmpty(userData.searchType)"
      id="mainTable"
      hidden
      data-cols-width="20"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table
          dense
          class="tableCss1"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="4"
                >
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffbbff"
                  colspan="8"
                >
                  GF
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="F9C100"
                  colspan="18"
                >
                  1F
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block A
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block B
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block C
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block D
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block E
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block F
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  -
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block G
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block H
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block I
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor GF-1
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor GF-2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor GF-3
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor GF-4
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-1
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-3
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  Cor 1F-4
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-5
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-6
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-7
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-8
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 1F-9
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  U↑
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  D↓
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="`${index}${item.time}`"
              >
                <td
                  v-if="userData.searchType === 1 && index % 2 === 0"
                  data-a-h="center"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                  :rowspan="index % 2 === 0 ?'2':'' "
                >
                  {{ item.time }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  data-a-h="center"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count2).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count13).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count16).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count17).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count18).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count19 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count19).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count20 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count20).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count21 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count21).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count22 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count22).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count23 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count23).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count24 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count24).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count25 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count25).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count26 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count26).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table1FooterData"
                :key="`${index}${item.time}`"
              >
                <template v-if="index ===0">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count16).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count17).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count18).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count19 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count19).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count20 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count20).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count21 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count21).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count22 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count22).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count23 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count23).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count24 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count24).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count25 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count25).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count26 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count26).replace(',','').replace(',','') }}
                  </td>
                </template>
                <template v-if="index ===1">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </td>
                </template>
                <template v-if="index ===2">
                  <td
                    td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    rowspan="2"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count11 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count12 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count16 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count17 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count18 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                </template>
                <template v-if="index ===3">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count16).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count17).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count18).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                </template>
                <template v-if="index ===4 || index ===5">
                  <td
                    td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count1).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count1).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count2).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count2).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count3).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count3).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count4).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count4).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count5).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count5).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count6).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count6).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count7).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count7).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count8).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count8).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count9).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count9).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count10).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count10).replace('%',''))/100 }}
                    </p>
                  </td>
                </template>
                <template v-if="index ===6 || index ===7">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 6 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                    colspan="8"
                  >
                    <p v-if="index === 6">
                      {{ String(item.count1).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 7">
                      {{ Number(String(item.count1).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 6 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                    colspan="18"
                  >
                    <p v-if="index === 6">
                      {{ String(item.count2).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 7">
                      {{ Number(String(item.count2).replace('%',''))/100 }}
                    </p>
                  </td>
                </template>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <table><tr></tr></table>
      <v-col
        cols="12"
        md="12"
      >
        <v-simple-table
          dense
          class="tableCss1"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  rowspan="4"
                >
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ccffcc"
                  colspan="18"
                >
                  2F
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="64fcfc"
                  colspan="8"
                >
                  3F
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Block J
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block K
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  -
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block L
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block M
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  -
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block N
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="4"
                >
                  Block O
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-1
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-3
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  Cor 2F-4
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-5
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-6
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-7
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 2F-8
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                  data-fill-color="C0C0C0"
                >
                  Cor 2F-9
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 3F-1
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 3F-2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 3F-3
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  colspan="2"
                >
                  Cor 3F-4
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  U↑
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  D↓
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="C0C0C0"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  L←
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  →R
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="`${index}${item.time}`"
              >
                <td
                  v-if="userData.searchType === 1 && index % 2 === 0"
                  data-a-h="center"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                  :rowspan="index % 2 === 0 ?'2':'' "
                >
                  {{ item.time }}
                </td>
                <td
                  v-if="userData.searchType === 2"
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count1).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count2).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count3).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count4).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count5).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count6).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count7).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count8).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count9).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count10).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count11).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count12).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                  :colspan="userData.searchType === 1 && index % 2 === 1 ?'2':'' "
                >
                  {{ String(item.count13).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count14).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count15).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count16).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count17).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count18).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count19 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count19).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count20 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count20).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count21 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count21).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count22 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count22).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count23 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count23).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count24 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count24).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count25 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count25).replace(',','').replace(',','') }}
                </td>
                <td
                  v-if="index % 2 === 0 || userData.searchType === 2"
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count26 === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.count26).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table2FooterData"
                :key="`${index}${item.time}`"
              >
                <template v-if="index ===0">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count16).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count17 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count17).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count18 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count18).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count19 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count19).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count20 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count20).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count21 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count21).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count22 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count22).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count23 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count23).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count24 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count24).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count25 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count25).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count26 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count26).replace(',','').replace(',','') }}
                  </td>
                </template>
                <template v-if="index ===1">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count1 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count1).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count2 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count2).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count5 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count5).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count6 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count6).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count11 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count11).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count12 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count12).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    colspan="2"
                    :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                  >
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </td>
                </template>
                <template v-if="index ===2">
                  <td
                    td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    rowspan="2"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count3 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count4 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count7 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count8 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count9 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    colspan="2"
                  >
                    {{ item.count10 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count13 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count14 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count15 }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    colspan="2"
                    data-fill-color="ffff88"
                  >
                    {{ item.count16 }}
                  </td>
                </template>
                <template v-if="index ===3">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count3 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count3).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count4 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count4).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count7 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count7).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count8 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count8).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count9 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count9).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count10 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count10).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    -
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count13 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count13).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count14 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count14).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count15 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count15).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="item.count16 === '0' ? '0':'###,###'"
                    colspan="2"
                  >
                    {{ String(item.count16).replace(',','').replace(',','') }}
                  </td>
                </template>
                <template v-if="index ===4 || index ===5">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count1).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count1).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count2).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count2).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count3).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count3).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count4).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count4).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count5).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count5).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                    colspan="2"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count6).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count6).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count7).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count7).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 4 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                    colspan="4"
                  >
                    <p v-if="index === 4">
                      {{ String(item.count8).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 5">
                      {{ Number(String(item.count8).replace('%',''))/100 }}
                    </p>
                  </td>
                </template>
                <template v-if="index ===6 || index ===7">
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 6 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                    colspan="18"
                  >
                    <p v-if="index === 6">
                      {{ String(item.count1).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 7">
                      {{ Number(String(item.count1).replace('%',''))/100 }}
                    </p>
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                    data-t="n"
                    :data-num-fmt="index == 6 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                    colspan="8"
                  >
                    <p v-if="index === 6">
                      {{ String(item.count2).replace(',','').replace(',','') }}
                    </p>
                    <p v-if="index === 7">
                      {{ Number(String(item.count2).replace('%',''))/100 }}
                    </p>
                  </td>
                </template>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <br>
    <v-col>
      <v-btn
        v-if="!loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import tableToExcelJs from '@linways/table-to-excel'
import { isEmpty, date2yyyymmddhhmmss } from '@core/utils'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 1) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },
    getColor(time, index) {
      let color = ''
      if (this.userData.searchType === 1) {
        // if (index % 2 === 1) {
        //   color = ''
        // } else {
        //   color = 'c0c0c0'
        // }
        console.log(index)
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else {
        color = ''
      }

      return color
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
  },
  setup() {
    return {
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      isEmpty,
    }
  },
}
</script>

<style scoped>
.tableCss1 th,
td {
  white-space: nowrap;
  padding: 0px 5px !important;
  font-size: 12px !important;
}
.tableCss1 tfoot tr {
  background: #fffccc !important;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}
.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  min-width: 60px;
  padding: 0px 1px !important;
  /* border: 1px solid #000; */
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  height: 14px;
  /* border: 1px solid #000; */
}
</style>
