<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div v-if="userData.searchType === 1 && !isEmpty(userData.searchType)">
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '520px'}"
      ></div>
    </div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-if="userData.searchType === 1 && !isEmpty(userData.searchType)"
          v-model="countDisplayswitch"
          flat
          :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay"
        ></v-switch>
    </div>
    <div v-if="userData.searchType === 2 && !isEmpty(userData.searchType)">
      <div
        ref="blockChart"
        :style="{ width: '100%', height: '520px'}"
      ></div>
    </div>
    <br>
    <br>
    <floorplan
      v-if="!isEmpty(userData.image_src)"
      id="exportMapDiv"
      :img-src="require(`@/assets${userData.image_src}`)"
      :image-width="userData.image_witdh"
      :image-height="userData.image_height"
      :block-data="userData.blockData"
      :entrance-data="userData.entranceData"
      :arrow-data="userData.arrowData"
    >
    </floorplan>
    <br>
    <div>
      <v-btn
        icon
        small
        @click="exportMaptoImage"
      >
        <v-icon
          dark
          right
        >
          {{ icons.mdiArrowCollapseDown }}
        </v-icon>
      </v-btn>
    </div>
    <br>
    <le-chan-cor
      v-if="siteId === '16' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></le-chan-cor>
    <ha-dong-cor
      v-if="siteId === '15' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></ha-dong-cor>
    <tan-phu-cor
      v-if="siteId === '12' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></tan-phu-cor>
    <long-bien-cor
      v-if="siteId === '8' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></long-bien-cor>
    <binh-duong-cor
      v-if="siteId === '3' && !isEmpty(userData)"
      :user-data="userData"
      :loading="loading"
    ></binh-duong-cor>
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiArrowCollapseDown } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import store from '@/store'
import corridorStoreModule from './corridorStoreModule'
import tableToExcelJs from '@linways/table-to-excel'
import html2canvas from 'html2canvas'
import _ from 'lodash'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan.vue'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'
import LeChanCor from './LeChan/LeChanCor.vue'
import HaDongCor from './HaDong/HaDongCor.vue'
import TanPhuCor from './TanPhu/TanPhuCor.vue'
import LongBienCor from './LongBien/LongBienCor.vue'
import BinhDuongCor from './BinhDuong/BinhDuongCor.vue'

const USER_APP_STORE_MODULE_NAME = 'app-corridor'

// Register module
if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
  store.registerModule(USER_APP_STORE_MODULE_NAME, corridorStoreModule)
}

const userData = ref([])
const echarts = require('echarts')

export default {
  components: {
    floorplan,
    Loading,
    LeChanCor,
    HaDongCor,
    TanPhuCor,
    LongBienCor,
    BinhDuongCor,
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        if (val.searchType === 1) {
          this.drawLine(val)
        } else {
          this.drawBlock(val)
        }
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 1) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },
    getColor(time, index) {
      let color = ''
      if (this.userData.searchType === 1) {
        console.log(index)

        // if (index % 2 === 1) {
        //   color = ''
        // } else {
        //   color = 'c0c0c0'
        // }
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else {
        color = ''
      }

      return color
    },
    toggleChange() {
      console.log(this.toggleExclusive)
      if (this.toggleExclusive === 'Daily') {
        store
          .dispatch('app-corridor/fetchData', { id: 2 })
          .then(response => {
            // userData.value = response.data.result.data
            this.loading = false

            userData.value = response.data
          })
          .catch(error => {
            // ログイン画面に遷移する
            console.log(error)
            this.loading = false

            // this.$router.push({ name: 'error-login' })
          })
      } else if (this.toggleExclusive === 'Monthly') {
        store
          .dispatch('app-corridor/fetchData', { id: 1 })
          .then(response => {
            // userData.value = response.data.result.data
            this.loading = false

            userData.value = response.data
          })
          .catch(error => {
            // ログイン画面に遷移する
            console.log(error)
            this.loading = false

            // this.$router.push({ name: 'error-login' })
          })
      } else if (this.toggleExclusive === 'Yearly') {
        store
          .dispatch('app-corridor/fetchData', { id: 3 })
          .then(response => {
            // userData.value = response.data.result.data
            this.loading = false

            userData.value = response.data
          })
          .catch(error => {
            // ログイン画面に遷移する
            console.log(error)
            this.loading = false

            // this.$router.push({ name: 'error-login' })
          })
      }
    },

    // 検索処理を行う
    searchOperation(startDate, endDate) {
      this.loading = true
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      store
        .dispatch('app-corridor/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          // userData.value = response.data

          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []
      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: val.labelShow,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }
      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: '5%',
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }

      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },

    // block chart作成する
    // https://juejin.cn/post/6862603100612624397
    drawBlock(val) {
      let yAxisData = new Set()
      const legendData = []
      _.forEach(val.blockChartData, d => {
        legendData.push(d.name)
        _.forEach(d.data, item => {
          yAxisData.add(item.label)
        })
      })
      const top = 60
      const bottom = 60

      yAxisData = [...yAxisData]

      const blockChartOption = {
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        toolbox: {
          bottom: 0,
          left: '5%',
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        legend: {
          left: '35%',
          top: 24,
          align: 'left',
          itemWidth: 15,
          itemHeight: 11,
          itemGap: 15,
          borderRadius: 4,
          textStyle: {
            color: '#262C41',
            fontSize: 18,
          },
          data: legendData,
        },
        grid: [
          {
            left: '8%',
            width: '35%',
            containLabel: true,
            bottom,
          },
          {
            left: '47%',
            width: '0%',
            bottom: bottom + 16,
          },
          {
            right: '17%',
            width: '33%',
            containLabel: true,
            bottom,
          },
        ].map(item => _.merge({ top }, item)),
        xAxis: [
          {
            type: 'value',
            inverse: true,
            axisLabel: {
              show: true,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
            },
          },
          {
            gridIndex: 1,
            show: true,
          },
          {
            gridIndex: 2,
            type: 'value',
            axisLabel: {
              show: true,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
            },
          },
        ].map(item => _.merge(item, { axisLabel: { color: '#949AA8', margin: 0 }, splitLine: { lineStyle: { color: '#E0E0E0', type: 'dashed' } } })),
        yAxis: [
          {
            position: 'right',
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          {
            gridIndex: 1,
            position: 'center',
            axisLabel: {
              align: 'center',
              padding: [8, 0, 0, 0],
              fontSize: 12,
              color: '#262C41',
            },
            axisLine: {
              show: false,
            },
          },
          {
            gridIndex: 2,
            position: 'left',
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
        ].map(item => _.merge(item, { type: 'category', axisLine: { lineStyle: { color: '#E0E0E0' } }, data: yAxisData })),
        series: [
          {
            name: _.get(val.blockChartData, 'left.name'),
            label: {
              position: 'left',
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            itemStyle: {
              color: '#C6E2FF',
              barBorderRadius: [4, 0, 0, 4],
            },
            data: _.map(_.get(val.blockChartData, 'left.data'), d => d.value),
          },
          {
            xAxisIndex: 2,
            yAxisIndex: 2,
            name: _.get(val.blockChartData, 'right.name'),
            label: {
              position: 'right',
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            itemStyle: {
              color: '#ccaadd',
              barBorderRadius: [0, 4, 4, 0],
            },
            data: _.map(_.get(val.blockChartData, 'right.data'), d => d.value),
          },
        ].map(item => _.merge(item, { type: 'bar', barWidth: 11, label: { show: true, distance: 10 } })),
      }

      let blockChart = echarts.getInstanceByDom(this.$refs.blockChart)
      if (blockChart == null) {
        blockChart = echarts.init(this.$refs.blockChart)
      }
      blockChart.setOption(blockChartOption, true)
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    /* map印刷 */
    exportMaptoImage: () => {
      html2canvas(document.querySelector('#exportMapDiv')).then(canvas => {
        const downloadEle = document.createElement('a')
        downloadEle.href = canvas.toDataURL('image/png')
        downloadEle.download = `${date2yyyymmddhhmmss(new Date())}.jpeg`
        downloadEle.click()
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, corridorStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const calendarViewOptions = ['Daily', 'Monthly', 'Yearly']
    const toggleExclusive = ref(undefined)
    const countDisplayswitch = ref(true)

    return {
      countDisplayswitch,
      calendarViewOptions,
      toggleExclusive,
      userData,
      icons: {
        mdiDownloadOutline,
        mdiArrowCollapseDown,
      },
      loading: false,
      isEmpty,
      siteId: String(getSessionData('data').siteId),
    }
  },
}
</script>

<style scoped>
.tableCss1 th,
td {
  white-space: nowrap;
  padding: 0px 5px !important;
  font-size: 12px !important;
}
.tableCss1 tfoot tr {
  background: #fffccc !important;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}
.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  min-width: 60px;
  padding: 0px 1px !important;
  /* border: 1px solid #000; */
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  /* border: 1px solid #000; */
}
</style>
